<div class="container-fluid">
    <div class="row mt-4 mb-4">
        <div class="card-col col-12">
            <div class="card mb-2 mt-2 d-flex card-main">
                <div class="card-body">
                    <form [formGroup]="form" (ngSubmit)="Save()">
                        <div class="row">
                            <div class="col-2 col-sm-2 col-md-1">
                                <button type="button" class="btn btn-lg mb-1" class="btn btn-lg mb-1 btn-outline-success">
                                    <h1><i class="fas fa-chart-bar"></i></h1>
                                </button>
                            </div>
                            <div class="col-10 col-sm-10 col-md-11 justify-content-center align-self-center">
                                <h3>Descargar Tickets</h3>
                            </div>
                            <div class="mt-4 mb-4 col-6">
                                <label class="form-label">Desde</label>
                                <input type="date" class="form-select" formControlName="start">
                            </div>
                            <div class="mt-4 mb-4 col-6">
                                <label class="form-label">Hasta</label>
                                <input type="date" class="form-select" formControlName="end">
                            </div>
                            <div class="col-12 text-end">
                                <button class="btn btn-success" type="submit">Buscar</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>