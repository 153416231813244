import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  reports = [
    {
      group: "Productividad (4)",
      btn_state: "btn-outline-info",
      reports: [
        {
          name_a: "Desempeño",
          name_b: "por usuario",
          icon: "fas fa-user",
          url: "../user-reports"
        },
        {
          name_a: "Desempeño",
          name_b: "por grupo",
          icon: "fas fa-users",
          url: "../group-reports"
        },
        {
          name_a: "Tiempo",
          name_b: "de respuesta",
          icon: "fas fa-stopwatch",
          url: "../avg-reports"
        },
        // {
        //   name_a: "Distribución",
        //   name_b: "de desempeño",
        //   icon: "fas fa-balance-scale",
        //   url: "../user-reportss"
        // },
      ]
    },
    {
      group: "Concurrencia (4)",
      btn_state: "btn-outline-success",
      reports: [
        {
          name_a: "Casos",
          name_b: "por día",
          icon: "fas fa-calendar-check",
          url: "../horary-day-reports"
        },
        {
          name_a: "Casos",
          name_b: "por mes",
          icon: "fas fa-calendar-alt",
          url: "../daily-reports"
        },
        {
          name_a: "Casos",
          name_b: "de grupo",
          icon: "fas fa-users",
          url: "../group-ticket-reports"
        },
        {
          name_a: "Descargar",
          name_b: "Tickets",
          icon: "fas fa-chart-bar",
          url: "../all-reports"
        }
      ]
    },
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
