<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="form" (ngSubmit)="Save()">
        <div class="row">
            <div class="col-12 text-center">
                <i class="fas fa-address-card fa-3x"></i>
            </div>
        </div>
        <div class="section-user">
            <div class="text-center mb-2">
                <small>Información personal</small>
            </div>
            <div class="row">
                <div class="col-12 col-sm-12 col-md-6">
                    <div class="mb-3">
                        <label class="form-label">Nombres</label>
                        <input type="text" class="form-control" formControlName="firstname">
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6">
                    <div class="mb-3">
                        <label class="form-label">Apellidos</label>
                        <input type="text" class="form-control" formControlName="lastname">
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6">
                    <div class="mb-3">
                        <label class="form-label">Identificación</label>
                        <input type="number" class="form-control" formControlName="identification">
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6">
                    <div class="mb-3">
                        <label class="form-label">Genero</label>
                        <select class="form-select" formControlName="gender">
                            <option *ngFor="let item of gender" [value]="item.id">{{item.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6">
                    <div class="mb-3">
                        <label class="form-label">Tipo de Identificación</label>
                        <select class="form-select" formControlName="identification_type">
                            <option *ngFor="let item of types" [value]="item.id">{{item.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6">
                    <div class="mb-3">
                        <label class="form-label">Celular</label>
                        <input type="number" class="form-control" formControlName="phone">
                    </div>
                </div>
            </div>
        </div>
        <div class="section-user">
            <div class="text-center mb-2">
                <small>Información de ingreso</small>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="mb-3">
                        <label class="form-label">Correo electronico (correo para el inicio de sesión)</label>
                        <input type="email" class="form-control" formControlName="email">
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6">
                    <div class="mb-3">
                        <label class="form-label">Contraseña</label>
                        <input type="text" class="form-control" formControlName="pass">
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6">
                    <div class="mb-3">
                        <label class="form-label">Confirmar Contraseña</label>
                        <input type="text" class="form-control" formControlName="pass_confirm">
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6">
                    <div class="mb-3">
                        <label class="form-label">Role</label>
                        <select class="form-select" formControlName="role">
                            <option *ngFor="let item of roles" [value]="item.id">{{item.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6">
                    <div class="mb-3">
                        <label class="form-label">Grupo</label>
                        <select class="form-select" formControlName="group">
                            <option *ngFor="let item of groups" [value]="item.id">{{item.name}}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12" *ngIf="errors.length>0">
                <div class="alert alert-danger" role="alert">
                    <ul>
                        <li *ngFor="let error of errors">{{error}}</li>
                    </ul>
                </div>
            </div>
            <div class="col-12">
                <div class="d-grid gap-2">
                    <button class="btn btn-success" type="submit">Guardar</button>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Cerrar</button>
</div>