import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/services/notification.service';
import { ServiceObject } from 'src/app/models/service-object';
import { WebAPIService } from 'src/app/services/web-api.service';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-tickets-report',
  templateUrl: './tickets-report.component.html',
  styleUrls: ['./tickets-report.component.scss']
})
export class TicketsReportComponent implements OnInit {

  form!: FormGroup;

  constructor(private formBuilder: FormBuilder, private toast: NotificationService, private webAPI: WebAPIService) { }

  ngOnInit(): void {
    var today = new Date();
    this.form = this.formBuilder.group({
      start: ["", Validators.compose([Validators.required])],
      end: ["", Validators.compose([Validators.required])]
    });
  }

  Save() {
    if (this.form.invalid) {
      this.toast.showWarning("Todos los campos son obligatorios*", "");
    } else if (this.form.controls.end.value < this.form.controls.start.value) {
      this.toast.showWarning("Revise las fechas", "");
    } else {
      this.toast.showSuccess("Buscando...", "");
      var servObj = new ServiceObject("report/ticketsByDays/" + this.form.controls.start.value + "/" + this.form.controls.end.value);
      this.webAPI.GetAction(servObj)
        .then(x => {
          this.toast.showSuccess("Se encontraron " + (x.data.report.length) + " registros", "");
          if (x.data.report.length > 0)
            this.downloadFile(x.data.report);
        }).catch(x => { throw x.message; });
    }
  }

  downloadFile(data: any) {
    const replacer = (key: any, value: null) => value === null ? '' : value; // specify how you want to handle null values here
    const header = Object.keys(data[0]);
    let csv = data.map((row: { [x: string]: any; }) => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(';'));
    csv.unshift(header.join(';'));
    var BOM = "\uFEFF";
    let csvArray = csv.join('\r\n');
    csvArray = BOM + csvArray;
    var blob = new Blob([csvArray], { type: "text/csv;charset=utf-8" });
    FileSaver.saveAs(blob, "myFile.csv");
  }

}
