import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ServiceObject } from '../models/service-object';
import { AuthService } from '../services/auth.service';
import { WebAPIService } from '../services/web-api.service';
import { TicketFormComponent } from '../ticket-form/ticket-form.component';
import { TicketsFormComponent } from '../tickets-form/tickets-form.component';

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss']
})
export class TicketsComponent implements OnInit {

  status: any = [];
  users: any = [];
  priority: any = [];
  tickets: any = [];
  currentDate!: string;
  status_id!: string;
  searchForm!: FormGroup;
  p: number = 1;
  

  constructor(
    private webAPI: WebAPIService, 
    public authS: AuthService, 
    private formBuilder: FormBuilder, 
    private modalService: NgbModal,
    private router: ActivatedRoute
  ) { }

  ngOnInit(): void {
    var today = new Date();
    this.currentDate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + ' ' + today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    this.status_id = this.router.snapshot.params.status_id;

    this.searchForm = this.formBuilder.group({
      state: [this.status_id ? this.status_id : "0"],
      user: [{ value: (this.authS.user.roles[0].pivot.role_id == 3 ? this.authS.user.roles[0].pivot.id : "0"), disabled: this.authS.user.roles[0].pivot.role_id == 3 }],
      priority: ["0"],
      date: ["0"],
      id: "",
      names: ""
    });

    var servObj = new ServiceObject("status");
    this.webAPI.GetAction(servObj)
      .then(x => {
        servObj = <ServiceObject>x;
        this.status = x.data.status;
      }).catch(x => { throw x.message; });

    var servObj = new ServiceObject("user");
    this.webAPI.GetAction(servObj)
      .then(x => {
        servObj = <ServiceObject>x;
        this.users = x.data.users;
      }).catch(x => { throw x.message; });

    var servObj = new ServiceObject("priority");
    this.webAPI.GetAction(servObj)
      .then(x => {
        servObj = <ServiceObject>x;
        this.priority = x.data.priority;
      }).catch(x => { throw x.message; });

    this.GetTickets();
  }

  GetTickets() {
    var paramsMain = new HttpParams();
    this.tickets = [];

    paramsMain = paramsMain.append("special", 2);

    if (this.authS.user.roles[0].pivot.role_id == 3)
      paramsMain = paramsMain.append("user_role_id", this.authS.user.roles[0].pivot.id);

    if (this.searchForm.controls.state.value > 0)
      paramsMain = paramsMain.append("status_id", this.searchForm.controls.state.value);

    if (this.searchForm.controls.priority.value > 0)
      paramsMain = paramsMain.append("ticket_priority_id", this.searchForm.controls.priority.value);

    if (this.searchForm.controls.user.value > 0)
      paramsMain = paramsMain.append("user_role_id", this.searchForm.controls.user.value);

    if (this.searchForm.controls.id.value > 0)
      paramsMain = paramsMain.append("id", this.searchForm.controls.id.value);

    if (this.searchForm.controls.names.value.length > 0)
      paramsMain = paramsMain.append("names", this.searchForm.controls.names.value);

    var servObj = new ServiceObject("ticket");
    this.webAPI.GetAction(servObj, paramsMain)
      .then(x => {
        servObj = <ServiceObject>x;
        if (this.searchForm.controls.state.value > 0) {
          x.data.tickets.forEach((ticket: { statuses: any; }) => {
            if (this.searchForm.controls.state.value == 100)
              this.tickets.push(ticket)
            else if (ticket.statuses[ticket.statuses.length - 1].id == this.searchForm.controls.state.value)
              this.tickets.push(ticket)
          });
        } else {
          this.tickets = x.data.tickets;
        }
      }).catch(x => { throw x.message; });
  }

  open(item: any) {
    const modalRef = this.modalService.open(TicketsFormComponent, { size: 'lg', backdrop: 'static' })
    modalRef.componentInstance.item = item;
    modalRef.componentInstance.currentDate = this.currentDate;
    modalRef.result.then((result) => {
      if (result)
        this.GetTickets();
    });
  }

  createTicket() {
    const modalRef = this.modalService.open(TicketFormComponent, { size: 'lg', backdrop: 'static' });
  }

}
