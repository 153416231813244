import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { ServiceObject } from '../models/service-object';
import { AuthService } from '../services/auth.service';
import { NotificationService } from '../services/notification.service';
import { WebAPIService } from '../services/web-api.service';
import { TicketFormComponent } from '../ticket-form/ticket-form.component';
import { TicketsFormComponent } from '../tickets-form/tickets-form.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  title = environment.title;
  messageError!: string;
  id_ticket!: string;
  loginForm!: FormGroup;
  currentDate!: string;

  constructor(
    private authS: AuthService,
    private route: Router,
    private webAPI: WebAPIService,
    private formBuilder: FormBuilder,
    private notifyService: NotificationService,
    private router: ActivatedRoute,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    var today = new Date();
    this.id_ticket = this.router.snapshot.params.id_ticket;
    this.currentDate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + ' ' + today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    this.authS.logout();
    this.loginForm = this.formBuilder.group({
      email: ["", Validators.compose([Validators.required])],
      password: ["", Validators.compose([Validators.required])],
    });
    if (this.id_ticket) {
      var paramsMain = new HttpParams();
      paramsMain = paramsMain.append("id", this.id_ticket);
      var servObj = new ServiceObject("ticket_free");
      this.webAPI.GetAction(servObj, paramsMain)
        .then(x => {
          servObj = <ServiceObject>x;
          if (servObj.data.tickets[0]) {
            this.notifyService.showSuccess("Petición encontrada", "");
          } else {
            this.notifyService.showError("La Petición con ID " + this.id_ticket + " no existe", "");
          }
          const modalRef = this.modalService.open(TicketsFormComponent, { size: 'lg', backdrop: 'static' });
          modalRef.componentInstance.item = servObj.data.tickets[0];
          modalRef.componentInstance.currentDate = this.currentDate;
          modalRef.componentInstance.free = true;
        }).catch(x => { throw x.message; });
    }
    if (this.route.url.indexOf("support")==1) {
      const modalRef = this.modalService.open(TicketFormComponent, { size: 'lg', backdrop: 'static' });
      modalRef.componentInstance.free = true;
    }
  }

  login() {
    if (!this.loginForm.invalid) {
      var servObj = new ServiceObject('login');
      servObj.data = { email: this.loginForm.controls.email.value, password: this.loginForm.controls.password.value };
      this.webAPI.Login(servObj).then(x => {
        servObj = <ServiceObject>x;
        if (!servObj.status)
          throw new Error(servObj.message);

        this.authS.saveToken(x.data);
        this.route.navigateByUrl('home');
      }).catch(x => { });
    } else
      this.notifyService.showWarning("Revise las credenciales", "Ingrese correo y contraseña");
  }

}
